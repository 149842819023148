.image {
    @apply my-4 flex place-content-center;
}

.image.image-style-block-align-right {
    @apply inline text-right;
}

.image.image-style-block-align-left {
    @apply inline text-left;
}

.image.image-style-align-right {
    @apply md:float-right md:ml-4;
}

.image.image-style-align-left {
    @apply md:float-left md:mr-4;
}