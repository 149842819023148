h1, h2, h3 {
    @apply font-bold;
}

h1 {
    @apply text-2xl;
}

h2 {
    @apply text-xl;
}

h3 {
    @apply text-lg;
}

@screen sm {
    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-2xl;
    }

    h3 {
        @apply text-lg;
    }
}

