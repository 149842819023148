:root{
    --color-lf: var(--blue-lf);
    --blue-lf: #32368f;
    --purple-lf: #61579e;
}

@responsive {
    .text-lf {
        color: var(--color-lf);
    }

    @variants hover {
        .text-lf {
            color: var(--color-lf);
        }
    }
}

@responsive {
    .bg-lf {
        background-color: var(--color-lf);
    }

    @variants hover {
        .bg-lf {
            background-color: var(--color-lf);
        }
    }
}

@responsive {
    .border-lf {
        border-color: var(--color-lf);
    }

    @variants hover {
        .border-lf {
            border-color: var(--color-lf);
        }
    }
}