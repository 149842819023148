$size: 640x400;
$mdSize: 1024x400;
$lgSize: 1280x400;
$xlSize: 1536x400;
$xxlSize: 1980x400;

$purpleIcon: mapmarker-professionnels.png;
$blueIcon: mapmarker-particuliers.png;

$icon: https%3A%2F%2Flfgenieclimatique.fr/build/images/;
$anchor: center%7C45.997968,5.1755811;

$baseUrl: //api.jawg.io/static?zoom=15&center=45.997968,5.1755811&layer=jawg-light&format=png&access-token=QMccCfnFcTBBOfchPcKmyt1hMn1Ao2h5gjmOU7Qzqy5uQlMu008LyGftZw2qOX2d;

footer .map {
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
}

footer .map.blue {
    background-image: url("$baseUrl&marker=icon:$icon$blueIcon,anchor:$anchor&size=$size");
}

footer .map.purple {
    background-image: url("$baseUrl&marker=icon:$icon$purpleIcon,anchor:$anchor&size=$size");
}

@screen md {
    footer .map.blue {
        background-image: url("$baseUrl&marker=icon:$icon$blueIcon,anchor:$anchor&size=$mdSize");
    }

    footer .map.purple {
        background-image: url("$baseUrl&marker=icon:$icon$purpleIcon,anchor:$anchor&size=$mdSize");
    }
}

@screen lg {
    footer .map.blue {
        background-image: url("$baseUrl&marker=icon:$icon$blueIcon,anchor:$anchor&size=$lgSize");
    }

    footer .map.purple {
        background-image: url("$baseUrl&marker=icon:$icon$purpleIcon,anchor:$anchor&size=$lgSize");
    }
}

@screen xl {
    footer .map.blue {
        background-image: url("$baseUrl&marker=icon:$icon$blueIcon,anchor:$anchor&size=$xlSize");
    }

    footer .map.purple {
        background-image: url("$baseUrl&marker=icon:$icon$purpleIcon,anchor:$anchor&size=$xlSize");
    }
}

@screen 2xl {
    footer .map.blue {
        background-image: url("$baseUrl&marker=icon:$icon$blueIcon,anchor:$anchor&size=$xxlSize");
    }

    footer .map.purple {
        background-image: url("$baseUrl&marker=icon:$icon$purpleIcon,anchor:$anchor&size=$xxlSize");
    }
}

footer nav ul {
    max-width: 50%;
    overflow: hidden;
}