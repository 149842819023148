@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Poppins-Light.woff2') format('woff2'),
    url('../fonts/Poppins-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
         url('../fonts/Poppins-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/Poppins-LightItalic.woff2') format('woff2'),
    url('../fonts/Poppins-LightItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2'),
    url('../fonts/Poppins-MediumItalic.woff') format('woff');
    font-display: swap;
}